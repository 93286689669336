import React,{ Component } from 'react';


class Login extends Component{

    render(){
        return(
            <div>
                <h1>Login Page</h1>
            </div>
        )
    }

}

export default Login;